import PropTypes from 'prop-types';
import React, { Component } from 'react';
import userService from '../../services/user-service';
import { getRandomBytesBase64 } from '../../utils/crypto';
import { isCookieCsrfTokenValid, handleCallbackRedirect, pushToLogAsUsers } from '../../utils/domain';
import BackToOfficeLink from '../common/BackToOfficeLink/BackToOfficeLink';
import Button from '../common/Button/Button';
import ErrorC from '../common/ErrorC/ErrorC';
import { AbstractLayout } from '../common/Layout/Layout';
import cookies from '../../services/cookies';

class SwitchUserView extends Component {
	static propTypes = {
		user: PropTypes.object.isRequired,
	};

	state = {
		loading: false,
		autoRedirect: false,
		error: '',
	};

	constructor() {
		super();
		this.url = new URL(window.location.href);
	}

	componentDidMount() {
		this.tryToSwitchUser();
	}

	get username() {
		return this.url.searchParams.get('username') || '';
	}

	get isTitaniumUser() {
		return this.props.user && this.props.user.isTitaniumUser;
	}

	get isTargetedUser() {
		return this.props.user.username === this.username;
	}

	tryToSwitchUser = () => {
		if (this.isTitaniumUser) {
			const crgLoginCsrfToken = cookies.get('crgLoginCsrfToken');
			if (this.isTargetedUser) {
				this.setState({
					loading: true,
					autoRedirect: true,
				});
				handleCallbackRedirect(decodeURIComponent(this.url.searchParams.get('callbackUrl')));
			} else if (
				crgLoginCsrfToken &&
				isCookieCsrfTokenValid(crgLoginCsrfToken)
			) {
				this.setState({ autoRedirect: true });
				this.switchUser();
			}
		} else {
			this.setState({
				loading: false,
			});
		}
	};

	switchUser = () => {
		const sessionCookie = cookies.get('crgSessionId');
		if (!sessionCookie) {
			this.setState({ error: 'You are not authenticated.' });
			return;
		}
		this.setState({ loading: true, error: '' });

		userService
			.withCredentials({ sessionId: sessionCookie })
			.authViaDelegatedAccess(this.username, { forResults: ['SessionId'] })
			.then(({ sessionId }) => {
				cookies.set('crgSessionId', sessionId, {
					domain: process.env.REACT_APP_ROOT_DOMAIN,
				});
				cookies.set('crgLoginCsrfToken', getRandomBytesBase64(20), {
					domain: process.env.REACT_APP_ROOT_DOMAIN,
				});
				pushToLogAsUsers(this.username);
				handleCallbackRedirect(decodeURIComponent(this.url.searchParams.get('callbackUrl')));
			})
			.catch(this.error);
	};

	destroySession = () => {
		const { user } = this.props;
		const cookie = cookies.get('crgSessionId');
		this.setState({
			loading: true,
			error: '',
		});
		return userService.endSession(cookie)
			.then(() => {
				user.logout();
			})
			.catch((err) => {
				this.setState({
					error: err.message,
					loading: false,
				});
			});
	};

	error = (err) => {
		this.setState({
			error: err.message,
			loading: false,
			autoRedirect: false,
		});
	};

	render() {
		const { loading, error, autoRedirect } = this.state;
		let title = 'You are not logged in as a Autoeurope user';
		let button = <Button type="button" onClick={this.destroySession} autoFocus>Log out</Button>;
		if (this.isTitaniumUser) {
			button = <Button type="button" onClick={this.switchUser} autoFocus>Switch user</Button>;
			title = autoRedirect ? '' : `Are you sure you want to switch user from "${this.props.user.username}" to "${this.username}"?`;
			if (!this.username) {
				title = 'You haven\'t specified username';
				button = null;
			}
		}
		const back = <BackToOfficeLink title={`Continue as "${this.props.user.username}"`} />;

		return (
			<AbstractLayout
				title={title}
				buttonNode={button}
				linkNode={back}
				loading={loading}
			>
				{error && <ErrorC>{error}</ErrorC>}
			</AbstractLayout>
		);
	}
}

export default SwitchUserView;
