import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import userService from '../../services/user-service';
import LoginView from '../LoginView/LoginView';
import LogoutView from '../LogoutView/LogoutView';
import NotFoundView from '../NotFoundView/NotFoundView';
import SwitchUserView from '../SwitchUserView/SwitchUserView';
import BrokerConfig from '../../broker-config';
import cookies from '../../services/cookies';
import VerifyView from '../LoginView/VerifyView';

/**
 * Load scripts to app.
 *
 * @param {Object} options
 * {string} options.src - File location
 * {boolean} [options.async=true] - Specifies file loading method.
 * {boolean} [options.head=true] - Whether to load in head or after body.
 */
function loadScript(options) {
	if (options.src) {
		const script = document.createElement('script');
		if (options.head === undefined) {
			options.head = true;
		}
		if (options.async === undefined) {
			options.async = true;
		}
		script.src = options.src;
		script.async = options.async;
		document.getElementsByTagName(options.head ? 'head' : 'body')[0].appendChild(script);
	}
}

function loadScripts() {
	loadScript({ src: process.env.REACT_APP_FRONTEND_LOGS });
}

class Routes extends Component {
	state = {
		loading: true,
		user: undefined,
		error: '',
	};

	componentDidMount() {
		loadScripts();
		this.authenticateViaCookie();
	}

	get isTitaniumUser() {
		return this.state.user &&
			(
				this.state.user.topAgencyId === undefined ||
				(this.state.user.via && this.state.user.via.username)
			);
	}

	authenticateViaCookie = () => {
		const cookie = cookies.get('crgSessionId');
		if (!cookie) {
			this.setState({ loading: false });
			return;
		}
		userService.authViaSessionId(cookie)
			.then((res) => {
				this.setState({
					loading: false,
					user: res.info,
				});
			})
			.catch((err) => {
				const state = { loading: false };
				if (err.name !== 'UnauthorizedError') {
					state.error = err.message;
				}
				this.setState(state);
			});
	};

	logout = () => {
		cookies.remove('crgSessionId', {
			domain: process.env.REACT_APP_ROOT_DOMAIN,
		});
		cookies.remove('crgLoginCsrfToken', {
			domain: process.env.REACT_APP_ROOT_DOMAIN,
		});

		const brokerConfig = BrokerConfig.getCurrentBrokerName();
		window.location.replace(this.isTitaniumUser ? '/support' : `/${brokerConfig}`);
	};

	userProps = () => {
		const { user } = this.state;
		if (!user) {
			return;
		}
		const userProps = { ...user };
		userProps.logout = this.logout;
		userProps.isTitaniumUser = this.isTitaniumUser;
		return userProps;
	};

	render() {
		const { user, loading, error } = this.state;
		if (error) {
			throw new Error(error);
		}
		if (loading) {
			return null;
		}

		return (
			<Router>
				<Fragment>
					{user && (
						<Switch>
							<Route exact path="(/logout|/gsa-hertz/logout)" render={r => <LogoutView {...r} user={this.userProps()} />} />
							<Route exact path="(/switchUser|/gsa-hertz/switchUser)" render={r => <SwitchUserView {...r} user={this.userProps()} />} />
							<Route exact path="(/|/support|/godryv|/gsa-hertz)" render={r => <LoginView {...r} user={this.userProps()} />} />
							<Route component={NotFoundView} />
						</Switch>
					)}
					{!user && (
						<Switch>
							<Route exact path="(/|/support|/switchUser|/logout|/godryv|/gsa-hertz|/sso)" component={LoginView} />
							<Route exact path="(/sso/verify)" render={r => <VerifyView {...r} user={this.userProps()} />} />
							<Route component={NotFoundView} />
						</Switch>
					)}
				</Fragment>
			</Router>
		);
	}
}

export default Routes;
