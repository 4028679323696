import React from 'react';
import './SSOLoginMethods.scss';

const SSOLoginMethods = () => {
	const handleSSOLogin = async () => {
		window.location.href = `${process.env.REACT_APP_USER_SERVICE_URL}/auth/saml/duo/login`;
	};

	return (
		<div className="sso-divider">
			<span>Other login methods</span>
			<div className="sso-grid">
				<div className="sso-button-container">
					<button id="duo" className="sso-button" onClick={handleSSOLogin}>
						<div className="duo-logo" />
						<span>Sign in with Duo</span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default SSOLoginMethods;
