import React, { useEffect } from 'react';
import { FormLayout } from '../common/Layout/Layout';
import brokerConfig from '../../broker-config';
import userService from '../../services/user-service';
import cookies from '../../services/cookies';
import { getRandomBytesBase64 } from '../../utils/crypto';

const VerifyView = () => {
	const loading = true;
	const theme = brokerConfig.getCurrentBrokerConfig().get('theme');
	const urlParams = new URLSearchParams(window.location.search);
	const referenceToken = urlParams.get('reference');

	useEffect(() => {
		if (!referenceToken) {
			window.location.replace('/sso');
		}

		async function getUserAccess() {
			const userAccess = await userService.authViaSSOReference(referenceToken);
			if (!userAccess || !userAccess.authResult || !userAccess.authResult.sessionId) {
				window.location.replace('/sso');
				return;
			}

			cookies.set('crgLoginCsrfToken', getRandomBytesBase64(20), {
				domain: process.env.REACT_APP_ROOT_DOMAIN,
			});
			cookies.set('crgSessionId', userAccess.authResult.sessionId, {
				domain: process.env.REACT_APP_ROOT_DOMAIN,
			});
			window.location.replace('/');
		}
		getUserAccess();
	}, [referenceToken]);

	return (
		<FormLayout
			loading={loading}
			title="Verifying"
			theme={theme}
		/>
	);
};

export default VerifyView;
